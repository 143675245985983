// HUNGARY
export const locale = {
  TRANSLATOR: {
    SELECT: "Válasszon nyelvet",
  },
  MENU: {
    NEW: "Új",
    ACTIONS: "Műveletek",
    CREATE_POST: "Új bejegyzés",
    PAGE_ADMINISTARATION: "Oldalak kezelése",
    NEWS: "Hírek",
    NEWS_CATEGORIES: "Kategóriák",
    NEWS_CATEGORY: "Kategória",
    EVENTS: "Események",
    EVENT: "Esemény",
    EVENT_CATEGORIES: "Kategóriák",
    EVENT_CATEGORY: "Kategória",
    ADDRESSES: "Helyszínek",
    ADDRESS: "Helyszín",
    PUBLICATIONS: "Díjeső",
    PUBLICATION: "Díj",
    PUBLICATION_CATEGORIES: "Évek",
    PUBLICATION_CATEGORY: "Év",
    PAGES: "Oldalak",
    PAGE: "Oldal",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    MEDIA_LIBRARY: "Médiatár",
    QUOTATIONS: "Ajánlások kezelése",
    QUOTATION_CATEGORY: "Ajánlás kategória",
    QUOTATION_CATEGORIES: "Ajánlás kategóriák",
    LOGO: "Logó",
    POPUP: "Popup",
    LOGOS: "Logók",
    POPUPS: "Popup",
    EXTERNAL_REFERENCE: "Külső hivatkozás",
    EXTERNAL_REFERENCES: "Külső hivatkozások",
    CAFETERIA_SATEMENT_DATA: "Cafeteria nyilatkozatok",
    CAFETERIASTATEMENTDATA: "Cafeteria nyilatkozat",
    CAFETERIA_STATEMENTS: "Cafeteria nyilatkozatok",
    COLLEAGUE: "Oktató",
    COLLEAGUES: "Oktatók",
    COLLEAGUES_ADMINISTRATION: "Oktatók kezelése",
    COLLEAGUE_CATEGORY: "Oktató kategória",
    COLLEAGUE_CATEGORIES: "Oktató kategóriák",
    QUOTATION: "Ajánlások kezelése",
    COOKIE_CATEGORIES: "Süti kategóriák",
    COOKIE_CATEGORY: "Süti kategória",
    COOKIE_MANAGEMENT: "Sütikezelés",
    COOKIE: "Süti",
    COOKIES: "Sütik",
    SETTINGS: "Beállítások",
    DEFAULT_SETTINGS: "Általános beállítások",
    DEFAULT_SETTING: "Általános beállítás",
    PAGE_TEMPLATE: "Oldal sablon",
    PAGE_TEMPLATES: "Oldal sablonok",
    TAG: "Címke",
    TAGS: "Címkék",
    PAGE_SETTINGS: "Weboldal beállítások",
    MENUS: "Menük kezelése",
    BANNERS: "Bannerek kezelése",
    FAQ: "Gyakran ismételt kérdések",
    CAREER: "Karrier",
    POSITIONS: "Pozíciók",
    PRODUCTS: "Termékek",
    PROPERTIES: "Tulajdonságok",
    PROPERTY: "Tulajdonság",
    CATEGORIES: "Kategóriák",
    CATEGORY: "Kategória",
    FILING_BOOK: "Iktatókönyv",
    FILING: "Iktató",
    PARTNER_CONNECTIONS: "Partnerkapcsolatok",
    INSTITUTES: "Intézmények",
    INSTITUTE: "Intézmény",
    INTERNAL_DOCUMENTS: "Dokumentumtár",

    filingBooks: "Iktató",
    systemSettings: "Rendszer beállítások",
    users: "Felhasználók",
    userGroups: "Felhasználói csoportok",
    roles: "Jogosultságok",
    currencies: "Pénznemek",
    countries: "Országok",
    companySettings: "Vállalati beállítások",
    departments: "Részlegek",
    sites: "Weboldalak",
    banners: "Bannerek",
    banner: "Banner",
    contact_form: "Űrlapok",
    contact_form_editor: "Űrlapszerkesztő",
    BLOG_CATEGORIES: "Kategóriák",
    BLOG_CATEGORY: "Kategória",
    BLOGS: "Blog",
    POSTS: "Bejegyzések",
    POST: "Bejegyzés",
    quotations: "Ajánlások",
    quotation: "Ajánlás",
    bannerTypes: "Banner típusok",
    bannerType: "Banner típus",
    GALLERIES: "Galériák",
    GALLERY: "Galéria",
    INSTRUCTOR: "Előadó",
    TWO_FACTOR_AUTH: "Kétfaktoros autentikáció",
    EMPLOYEES: "Munkatársak",
    EMPLOYEE: "Munkatárs",
    incoming_contact_form: "Beérkezett űrlapok",
    FORM_TEMPLATES: "Űrlap sablonok",
    FORM_TEMPLATE: "Űrlap sablon",
    RED_LETTER_DAYS: "Nevezetes napok",
    RED_LETTER_DAY: "Munkaév",
    filing: "Iktató",
    FILING_BOOKS: "Iktató könyvek",
    filings: "Iktatók",
    holidayRequest: "Szabadság igények",
    institutes: "Intézmények",
  },
  ALERT: {
    choose_only_maxFiles: "Maximum {maxFiles} fájlt választhat!",
    file_info_title: "Fájl információk",
    created_at: "Létrehozva",
    name: "Neve",
    mime_type: "Típusa",
    path: "Elérés",
    size: "Méret",
    width: "Szélesség",
    height: "Magasság",
    pixel: "Képpont",
  },
  CONTACT_FORM: {
    contact_info: "Űrlap részletei",
    company_name: "Cégnév",
    created_at: "Létrehozva",
    first_name: "Keresztnév",
    last_name: "Vezetéknév",
    locale: "Nyelv",
    message: "Üzenet",
    phone: "Telefonszám",
    remote_ip: "Távoli IP cím",
    user_agent: "User agent",
    email: "E-mail",
    contact_form_id: "Kapcsolat űrlap ID",
    zip: "ZIP kód",
    city: "Város",
    street: "Utca",
    country: "Ország",
    referer: "Küldő űrlap",
    currency: "Pénznem",
    paymentId: "Fizetés ID",
    company_id: "Cég ID",
    package_id: "Csomag ID",
    vat_number: "Adószám",
    name: "Név",
    position_id: "Pozíció ID",
    position_name: "Pozíció neve",
  },
  AUTH: {
    WELCOME: "Üdvözöl a SYNERGY fox",
    HI: "Szia",
    USER_PROFILE: "Profil adatok",
    SIGN_OUT_BUTTON: "Kilépés",
    GENERAL: {
      OR: "Vagy",
      SUBMIT_BUTTON: "Beküldés",
      NO_ACCOUNT: "Nincs még felhasználói fiókja?",
      SIGNUP_BUTTON: "Regisztráció",
      FORGOT_BUTTON: "Elfelejtett jelszó",
      BACK_BUTTON: "Vissza",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Kapcsolat",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Belépés",
      SIGN_IN: "Belépés",
      TEXT: "Írja be e-mail címét és jelszavát",
      TEXT2F:
        "Kérjük, adja meg a kétfaktoros hitelesítés (2FA) ellenőrző kódját a bejelentkezéshez.",
    },
    FORGOT: {
      TITLE: "Elfelejtette jelszavát?",
      DESC: "Adja meg email címét a jelszó helyreállításához",
      SUCCESS: "Fiókját sikeresen visszaállítottuk.",
    },
    REGISTER: {
      TITLE: "Regisztráció",
      DESC: "A fiók létrehozásához adja meg adatait",
      SUCCESS: "Fiókját sikeresen létrehoztuk",
    },
    INPUT: {
      EMAIL: "Email cím",
      FULLNAME: "Név",
      PASSWORD: "Jelszó",
      CONFIRM_PASSWORD: "Jelszó megerősítése",
      USERNAME: "Felhasználónév",
      one_time_password: "Hitelesítési kód",
    },
    VALIDATION: {
      INVALID: "{{name}} érvénytelen",
      REQUIRED: "{{name}} megadása kötelező",
      MIN_LENGTH: "{{name}} minimum {{min}} karakter kell legyen",
      AGREEMENT_REQUIRED: "A felhasználási feltételek elfogadása kötelező",
      NOT_FOUND: "A kért {{name}} nem található",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Kötelező mező",
      MIN_LENGTH_FIELD: "Minimális karakterszám:",
      MAX_LENGTH_FIELD: "Maximális karakterszám:",
      INVALID_FIELD: "Érvénytelen mező",
      // "Invalid Email or Password": "Invalid Email or Password",
      token_expired: "A token lejárt, ezért kiléptettük",
    },
    VALIDATION_2FA: {
      title: "Kétfaktoros autentikáció (2FA)",
      text: "A kétfaktoros hitelesítés beállítása megerősíti a fiókod biztonságát a bejelentkezési folyamat során, védelmet nyújt a fiók illetéktelen hozzáférése ellen.",
      button: "2FA kulcs generálás",
      text1:
        "Töltsd le a Google Authenticator nevű applikációját. Szkenneld be a QR kódot az alkalmazással, vagy alternatívaként használd az alábbi beállításkulcsot:",
      text_qr:
        "Add meg a Google Authenticator alkalmazás által generált kódot:",
      placeholder: "Hitelesítő kód",
      button_qr: "Engedélyezés",
      majd_zolddel: "Kétfaktoros autentikáció (2FA) engedélyezve!",
      text_qr2:
        "Ha szeretnéd letiltani a kétfaktoros autentikációt, kattints a Letiltás gombra.",
      button2: "Letiltás",
      auth_code: "Hitelesítő kód",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  EMAIL: {
    offer: "Offer",
  },
  PERMISSIONS: {
    module_pages_menu: "Oldalak modul",
    module_contactForms_menu: "Űrlapok modul",
    module_quotations_menu: "Ajánlások modul",
    module_blog_menu: "Blog modul",
    module_logos_menu: "Logó modul",
    module_popups_menu: "Popup modul",
    module_colleagues_menu: "Oktatók modul",
    module_gdpr_menu: "Beállítások modul",
    module_news_menu: "Hírek modul",
    module_events_menu: "Események modul",
    module_external_references_menu: "Külső hivatkozások modul",
    module_galleries: "Galériák modul",
    module_publication_menu: "Kiadványok modul",
    module_faq_menu: "Gyakran ismételt kérdések modul",
    module_galleries_menu: "Galériák modul",
    module_career_menu: "Karrier modul",
    module_packages_menu: "Csomagok modul",
    module_products_menu: "Termékek modul",
    module_webshop_menu: "Webshop modul",
    module_instructor_menu: "Előadók modul",
    module_filings_menu: "Iktató modul",
    module_addresses_menu: "Helyszínek modul",
    module_partner_connections_menu: "Partnerkapcsolatok modul",
    module_partner_cafeteria_menu: "Cafeteria modul",
    module_institutes_menu: "Intézmények modul",

    institutes: "Intézmények",
    system_documentLibrary_menu: " Dokumentumtár menü",
    system_synergyfox_assistant: "Fox AI",
    external_references_setting_create:
      "Külső hivatkozások - Külső hivatkozás beállítás létrehozása",
    external_references_setting_delete:
      "Külső hivatkozások - Külső hivatkozás beállítás törlése",
    external_references_setting_view:
      "Külső hivatkozások - Külső hivatkozás beállítás megtekintése",
    external_references_setting_update:
      "Külső hivatkozások - Külső hivatkozás beállítás szerkesztése",
    external_references_setting_menu:
      "Külső hivatkozások - Külső hivatkozás beállítások menü",

    system_setting_menu: "Rendszer beállítások menü",
    system_setting_update:
      "Rendszer beállítások - Rendszer beállítások szerkesztése",
    system_setting_view:
      "Rendszer beállítások - Rendszer beállítások megtekintése",
    system_generalsetting_menu:
      "Rendszer beállítások - Általános beállítások menü",
    system_user_menu: "Rendszer beállítások - Felhasználók menü",
    system_user_create: "Rendszer beállítások - Felhasználó létrehozása",
    system_user_delete: "Rendszer beállítások - Felhasználó törlése",
    system_user_update: "Rendszer beállítások - Felhasználó szerkesztése",
    system_user_update_self:
      "Rendszer beállítások - Saját felhasználó szerkesztése",
    system_user_view: "Rendszer beállítások - Felhasználó megtekintése",
    system_user_view_self:
      "Rendszer beállítások - Saját felhasználó megtekintése",
    system_userGroup_menu: "Rendszer beállítások - Felhasználói csoportok menü",
    system_userGroup_create:
      "Rendszer beállítások - Felhasználói csoport létrehozása",
    system_userGroup_delete:
      "Rendszer beállítások - Felhasználói csoport törlése",
    system_userGroup_update:
      "Rendszer beállítások - Felhasználói csoport szerkesztése",
    system_userGroup_update_self:
      "Rendszer beállítások - Saját felhasználói csoport szerkesztése",
    system_userGroup_view:
      "Rendszer beállítások - Felhasználói csoport megtekintése",
    system_userGroup_view_self:
      "Rendszer beállítások - Saját felhasználói csoport megtekintése",
    system_role_menu: "Rendszer beállítások - Jogosultságok menü",
    system_role_create: "Rendszer beállítások - Jogosultság létrehozása",
    system_role_delete: "Rendszer beállítások - Jogosultság törlése",
    system_role_update: "Rendszer beállítások - Jogosultság  szerkesztése",
    system_role_view: "Rendszer beállítások - Jogosultság megtekintése",
    system_country_menu: "Rendszer beállítások - Országok menü",
    system_country_update: "Rendszer beállítások - Ország szerkesztése",
    system_country_view: "Rendszer beállítások - Ország megtekintése",
    system_currency_menu: "Rendszer beállítások - Pénznemek menü",
    system_currency_create: "Rendszer beállítások - Pénznem létrehozása",
    system_currency_delete: "Rendszer beállítások - Pénznem törlése",
    system_currency_update: "Rendszer beállítások - Pénznem szerkesztése",
    system_currency_view: "Rendszer beállítások - Pénznem megtekintése",

    instructors: "Előadók",
    instructors_instructor_create: "Előadók  - Előadó létrehozása",
    instructors_instructor_delete: "Előadók  - Előadó törlése",
    instructors_instructor_view: "Előadók  - Előadó megtekintése",
    instructors_instructor_update: "Előadók  - Előadó szerkesztése",
    instructors_instructor_menu: "Előadók  - Előadó menü",

    instructors_setting_create: "Előadók - Beállítás létrehozása",
    instructors_setting_delete: "Előadók - Beállítás törlése",
    instructors_setting_view: "Előadók - Beállítás megtekintése",
    instructors_setting_update: "Előadók - Beállítás szerkesztése",
    instructors_setting_menu: "Előadók - Beállítások menü",

    gdpr_setting_create: "GDPR  - GDPR létrehozása",
    gdpr_setting_delete: "GDPR  - GDPR törlése",
    gdpr_setting_view: "GDPR  - GDPR megtekintése",
    gdpr_setting_update: "GDPR  - GDPR szerkesztése",
    gdpr_setting_menu: "GDPR  - GDPR menü",

    pages_setting_create: "Oldalak  - Oldalbeállítás létrehozása",
    pages_setting_delete: "Oldalak  - Oldalbeállítás törlése",
    pages_setting_view: "Oldalak  - Oldalbeállítás megtekintése",
    pages_setting_update: "Oldalak  - Oldalbeállítás szerkesztése",
    pages_setting_menu: "Oldalak  - Oldalbeállítás menü",

    quotations_setting_create: "Ajánlások  - Ajánlás létrehozása",
    quotations_setting_delete: "Ajánlások  - Ajánlás törlése",
    quotations_setting_view: "Ajánlások  - Ajánlás megtekintése",
    quotations_setting_update: "Ajánlások  - Ajánlás szerkesztése",
    quotations_setting_menu: "Ajánlások  - Ajánlás menü",

    contactForms_setting_create: "Űrlapok  - Beállítás létrehozása",
    contactForms_setting_delete: "Űrlapok  - Beállítás törlése",
    contactForms_setting_view: "Űrlapok  - Beállítás megtekintése",
    contactForms_setting_update: "Űrlapok  - Beállítás szerkesztése",
    contactForms_setting_menu: "Űrlapok  - Beállítás menü",

    companySettings_department_menu: "Vállalati beállítások - Részlegek menü",
    companySettings_department_create:
      "Vállalati beállítások - Részleg létrehozása",
    companySettings_department_delete:
      "Vállalati beállítások - Részleg törlése",
    companySettings_department_update:
      "Vállalati beállítások - Részleg szerkesztése",
    companySettings_department_view:
      "Vállalati beállítások - Részleg megtekintése",
    companySettings_site_menu: "Vállalati beállítások - Telephelyek menü",
    companySettings_site_create:
      "Vállalati beállítások - Telephely létrehozása",
    companySettings_site_delete: "Vállalati beállítások - Telephely törlése",
    companySettings_site_update:
      "Vállalati beállítások - Telephely szerkesztése",
    companySettings_site_view: "Vállalati beállítások - Telephely megtekintése",

    pages_page_create: "Oldalak - Oldal létrehozása",
    pages_page_delete: "Oldalak - Oldal törlése",
    pages_page_view: "Oldalak - Oldal megtekintése",
    pages_page_update: "Oldalak - Oldal szerkesztése",
    pages_page_menu: "Oldalak - Oldalak menü",

    blog_tag_create: "Blog - Címke létrehozása",
    blog_tag_delete: "Blog - Címke törlése",
    blog_tag_view: "Blog - Címke megtekintése",
    blog_tag_update: "Blog - Címke szerkesztése",
    blog_tag_menu: "Blog - Címke menü",

    pages_menu_create: "Oldalak - Menü létrehozása",
    pages_menu_delete: "Oldalak - Menü törlése",
    pages_menu_view: "Oldalak - Menü megtekintése",
    pages_menu_update: "Oldalak - Menü szerkesztése",
    pages_menu_menu: "Oldalak - Menük menü",

    pages_banner_create: "Oldalak - Banner létrehozása",
    pages_banner_delete: "Oldalak - Banner törlése",
    pages_banner_view: "Oldalak - Banner megtekintése",
    pages_banner_update: "Oldalak - Banner szerkesztése",
    pages_banner_menu: "Oldalak - Bannerek menü",

    contactForms_contactForm_create: "Űrlapok - Űrlap létrehozása",
    contactForms_contactForm_delete: "Űrlapok - Űrlap törlése",
    contactForms_contactForm_view: "Űrlapok - Űrlap megtekintése",
    contactForms_contactForm_update: "Űrlapok - Űrlap szerkesztése",
    contactForms_contactForm_menu: "Űrlapok - Űrlapok menü",
    contactForms_contactFormEditor_menu: "Űrlapok - Űrlap szerkesztése menü",

    quotations_quotation_create: "Ajánlások - Ajánlás létrehozása",
    quotations_quotation_delete: "Ajánlások - Ajánlás törlése",
    quotations_quotation_view: "Ajánlások - Ajánlás megtekintése",
    quotations_quotation_update: "Ajánlások - Ajánlás szerkesztése",
    quotations_quotation_menu: "Ajánlások - Ajánlások menü",

    quotations_quotationCategory_create:
      "Ajánlások - Ajánlás kategória létrehozása",
    quotations_quotationCategory_delete:
      "Ajánlások - Ajánlás kategória törlése",
    quotations_quotationCategory_view:
      "Ajánlások - Ajánlás kategória megtekintése",
    quotations_quotationCategory_update:
      "Ajánlások - Ajánlás kategória szerkesztése",
    quotations_quotationCategory_menu: "Ajánlások - Ajánlás kategóriák menü",

    blog_category_create: "Blog - Kategória létrehozása",
    blog_category_delete: "Blog - Kategória törlése",
    blog_category_view: "Blog - Kategória megtekintése",
    blog_category_update: "Blog - Kategória szerkesztése",
    blog_category_menu: "Blog - Kategória menü",

    faq: "Gyakran ismételt kérdések",
    faq_category_create: "Gyakran ismételt kérdések  - Kategória létrehozása",
    faq_category_delete: "Gyakran ismételt kérdések  - Kategória törlése",
    faq_category_view: "Gyakran ismételt kérdések  - Kategória megtekintése",
    faq_category_update: "Gyakran ismételt kérdések  - Kategória szerkesztése",
    faq_category_menu: "Gyakran ismételt kérdések  - Kategória menü",

    blog_post_create: "Blog - Bejegyzés létrehozása",
    blog_post_delete: "Blog - Bejegyzés törlése",
    blog_post_view: "Blog - Bejegyzés megtekintése",
    blog_post_update: "Blog - Bejegyzés szerkesztése",
    blog_post_menu: "Blog - Bejegyzések menü",

    blog_setting_create: "Blog - Beállítás létrehozása",
    blog_setting_delete: "Blog - Beállítás törlése",
    blog_setting_view: "Blog - Beállítás megtekintése",
    blog_setting_update: "Blog - Beállítás szerkesztése",
    blog_setting_menu: "Blog - Beállítások menü",

    news_category_create: "Hírek - Kategória létrehozása",
    news_category_delete: "Hírek - Kategória törlése",
    news_category_view: "Hírek - Kategória megtekintése",
    news_category_update: "Hírek - Kategória szerkesztése",
    news_category_menu: "Hírek - Kategória menü",

    news_news_create: "Hírek - Bejegyzés létrehozása",
    news_news_delete: "Hírek - Bejegyzés törlése",
    news_news_view: "Hírek - Bejegyzés megtekintése",
    news_news_update: "Hírek - Bejegyzés szerkesztése",
    news_news_menu: "Hírek - Bejegyzések menü",

    news_tag_create: "Hírek - Címke létrehozása",
    news_tag_delete: "Hírek - Címke törlése",
    news_tag_view: "Hírek - Címke megtekintése",
    news_tag_update: "Hírek - Címke szerkesztése",
    news_tag_menu: "Hírek - Címke menü",

    news_setting_create: "Hírek - Beállítás létrehozása",
    news_setting_delete: "Hírek - Beállítás törlése",
    news_setting_view: "Hírek - Beállítás megtekintése",
    news_setting_update: "Hírek - Beállítás szerkesztése",
    news_setting_menu: "Hírek - Beállítások menü",

    events_category_create: "Események - Kategória létrehozása",
    events_category_delete: "Események - Kategória törlése",
    events_category_view: "Események - Kategória megtekintése",
    events_category_update: "Események - Kategória szerkesztése",
    events_category_menu: "Események - Kategória menü",

    events_event_create: "Események - Esemény létrehozása",
    events_event_delete: "Események - Esemény törlése",
    events_event_view: "Események - Esemény megtekintése",
    events_event_update: "Események - Esemény szerkesztése",
    events_event_menu: "Események - Események menü",

    events_tag_create: "Események - Címke létrehozása",
    events_tag_delete: "Események - Címke törlése",
    events_tag_view: "Események - Címke megtekintése",
    events_tag_update: "Események - Címke szerkesztése",
    events_tag_menu: "Események - Címke menü",

    events_setting_create: "Események - Beállítás létrehozása",
    events_setting_delete: "Események - Beállítás törlése",
    events_setting_view: "Események - Beállítás megtekintése",
    events_setting_update: "Események - Beállítás szerkesztése",
    events_setting_menu: "Események - Beállítások menü",

    publication_category_create: "Kiadványok - Kategória létrehozása",
    publication_category_delete: "Kiadványok - Kategória törlése",
    publication_category_view: "Kiadványok - Kategória megtekintése",
    publication_category_update: "Kiadványok - Kategória szerkesztése",
    publication_category_menu: "Kiadványok - Kategória menü",

    publication_post_create: "Kiadványok - Bejegyzés létrehozása",
    publication_post_delete: "Kiadványok - Bejegyzés törlése",
    publication_post_view: "Kiadványok - Bejegyzés megtekintése",
    publication_post_update: "Kiadványok - Bejegyzés szerkesztése",
    publication_post_menu: "Kiadványok - Bejegyzések menü",

    publication_setting_create: "Kiadványok - Beállítás létrehozása",
    publication_setting_delete: "Kiadványok - Beállítás törlése",
    publication_setting_view: "Kiadványok - Beállítás megtekintése",
    publication_setting_update: "Kiadványok - Beállítás szerkesztése",
    publication_setting_menu: "Kiadványok - Beállítás menü",

    pages_bannerType_create: "Oldalak - Banner típus létrehozása",
    pages_bannerType_delete: "Oldalak - Banner típus törlése",
    pages_bannerType_view: "Oldalak - Banner típus megtekintése",
    pages_bannerType_update: "Oldalak - Banner típus szerkesztése",
    pages_bannerType_menu: "Oldalak - Banner típusok menü",

    logos_category_create: "Logó - Logó létrehozása",
    logos_category_delete: "Logó - Logó törlése",
    logos_category_view: "Logó - Logó megtekintése",
    logos_category_update: "Logó - Logó szerkesztése",
    logos_category_menu: "Logó - Logók menü",

    logos_setting_create: "Logó - Beállítás létrehozása",
    logos_setting_delete: "Logó - Beállítás törlése",
    logos_setting_view: "Logó - Beállítás megtekintése",
    logos_setting_update: "Logó - Beállítás szerkesztése",
    logos_setting_menu: "Logó - Beállítás menü",

    popups_category_create: "Popup - Popup létrehozása",
    popups_category_delete: "Popup - Popup törlése",
    popups_category_view: "Popup - Popup megtekintése",
    popups_category_update: "Popup - Popup szerkesztése",
    popups_category_menu: "Popup - Popup menü",

    popups_setting_create: "Popup - Beállítás létrehozása",
    popups_setting_delete: "Popup - Beállítás törlése",
    popups_setting_view: "Popup - Beállítás megtekintése",
    popups_setting_update: "Popup - Beállítás szerkesztése",
    popups_setting_menu: "Popup - Beállítás menü",

    external_references_category_create:
      "Külső hivatkozások - Külső hivatkozás létrehozása",
    external_references_category_delete:
      "Külső hivatkozások - Külső hivatkozás törlése",
    external_references_category_view:
      "Külső hivatkozások - Külső hivatkozás megtekintése",
    external_references_category_update:
      "Külső hivatkozások - Külső hivatkozás szerkesztése",
    external_references_category_menu:
      "Külső hivatkozások - Külső hivatkozások menü",

    colleagues_colleagueCategory_create:
      "Oktatók - Oktató kategória létrehozása",
    colleagues_colleagueCategory_delete: "Oktatók - Oktató kategória törlése",
    colleagues_colleagueCategory_view:
      "Oktatók - Oktató kategória megtekintése",
    colleagues_colleagueCategory_update:
      "Oktatók - Oktató kategória szerkesztése",
    colleagues_colleagueCategory_menu: "Oktatók - Oktató kategóriák menü",

    colleagues_colleague_create: "Oktatók - Oktató létrehozása",
    colleagues_colleague_delete: "Oktatók - Oktató törlése",
    colleagues_colleague_view: "Oktatók - Oktató megtekintése",
    colleagues_colleague_update: "Oktatók - Oktató szerkesztése",
    colleagues_colleague_menu: "Oktatók - Oktatók menü",

    colleagues_setting_create: "Oktatók - Beállítás létrehozása",
    colleagues_setting_delete: "Oktatók - Beállítás törlése",
    colleagues_setting_view: "Oktatók - Beállítás megtekintése",
    colleagues_setting_update: "Oktatók - Beállítás szerkesztése",
    colleagues_setting_menu: "Oktatók - Beállítások menü",

    pages_pageTemplate_create: "Oldalak - Oldal sablon létrehozása",
    pages_pageTemplate_delete: "Oldalak - Oldal sablon törlése",
    pages_pageTemplate_view: "Oldalak - Oldal sablon megtekintése",
    pages_pageTemplate_update: "Oldalak - Oldal sablon szerkesztése",
    pages_pageTemplate_menu: "Oldalak - Oldal sablonok menü",

    gdpr_category_create: "Beállítások - Süti kategória létrehozása",
    gdpr_category_delete: "Beállítások - Süti kategória törlése",
    gdpr_category_view: "Beállítások - Süti kategória megtekintése",
    gdpr_category_update: "Beállítások - Süti kategória szerkesztése",
    gdpr_category_menu: "Beállítások - Süti kategóriák menü",

    gdpr_code_create: "Beállítások - Süti létrehozása",
    gdpr_code_delete: "Beállítások - Süti törlése",
    gdpr_code_view: "Beállítások - Süti megtekintése",
    gdpr_code_update: "Beállítások - Süti szerkesztése",
    gdpr_code_menu: "Beállítások - Sütik menü",

    system_site_view: "Beállítások - Weboldal beállítások megtekintése",
    system_site_update: "Beállítások - Weboldal beállítások szerkesztése",
    system_site_menu: "Beállítások - Weboldal beállítások menü",
    system_mediaLibrary_menu: "Médiatár menü",

    galleries_category_create: "Galériák - Galéria létrehozása",
    galleries_category_delete: "Galériák - Galéria törlése",
    galleries_category_view: "Galériák - Galéria megtekintése",
    galleries_category_update: "Galériák - Galéria szerkesztése",
    galleries_category_menu: "Galériák - Galériák menü",

    galleries_setting_create: "Galériák - Beállítás létrehozása",
    galleries_setting_delete: "Galériák - Beállítás törlése",
    galleries_setting_view: "Galériák - Beállítás megtekintése",
    galleries_setting_update: "Galériák - Beállítás szerkesztése",
    galleries_setting_menu: "Galériák - Beállítások menü",

    faq_setting_create: "Gyakran ismételt kérdések - Beállítás létrehozása",
    faq_setting_delete: "Gyakran ismételt kérdések - Beállítás törlése",
    faq_setting_view: "Gyakran ismételt kérdések - Beállítás megtekintése",
    faq_setting_update: "Gyakran ismételt kérdések - Beállítás szerkesztése",
    faq_setting_menu: "Gyakran ismételt kérdések - Beállítások menü",

    career: "Karrier",
    career_career_create: "Karrier - Pozíció létrehozása",
    career_career_delete: "Karrier - Pozíció törlése",
    career_career_view: "Karrier - Pozíció megtekintése",
    career_career_update: "Karrier - Pozíció szerkesztése",
    career_career_menu: "Karrier - Pozíciók menü",

    career_setting_create: "Karrier - Beállítás létrehozása",
    career_setting_delete: "Karrier - Beállítás törlése",
    career_setting_view: "Karrier - Beállítás megtekintése",
    career_setting_update: "Karrier - Beállítás szerkesztése",
    career_setting_menu: "Karrier - Beállítások menü",

    packages: "Csomagok",
    packages_package_create: "Csomagok - Csomag létrehozása",
    packages_package_delete: "Csomagok - Csomag törlése",
    packages_package_view: "Csomagok - Csomag megtekintése",
    packages_package_update: "Csomagok - Csomag szerkesztése",
    packages_package_menu: "Csomagok - Csomagok menü",

    packages_setting_create: "Csomagok - Beállítás létrehozása",
    packages_setting_delete: "Csomagok - Beállítás törlése",
    packages_setting_view: "Csomagok - Beállítás megtekintése",
    packages_setting_update: "Csomagok - Beállítás szerkesztése",
    packages_setting_menu: "Csomagok - Beállítások menü",

    products: "Termékek",
    products_property_create: "Termékek - Tulajdonság létrehozása",
    products_property_delete: "Termékek - Tulajdonság törlése",
    products_property_view: "Termékek - Tulajdonság megtekintése",
    products_property_update: "Termékek - Tulajdonság szerkesztése",
    products_property_menu: "Termékek - Tulajdonság menü",

    products_category_create: "Termékek - Kategória létrehozása",
    products_category_delete: "Termékek - Kategória törlése",
    products_category_view: "Termékek - Kategória megtekintése",
    products_category_update: "Termékek - Kategória szerkesztése",
    products_category_menu: "Termékek - Kategória menü",

    products_product_create: "Termékek - Termék létrehozása",
    products_product_delete: "Termékek - Termék törlése",
    products_product_view: "Termékek - Termék megtekintése",
    products_product_update: "Termékek - Termék szerkesztése",
    products_product_menu: "Termékek - Termék menü",

    products_price_list_create: "Termékek - Árlista létrehozása",
    products_price_list_delete: "Termékek - Árlista törlése",
    products_price_list_view: "Termékek - Árlista megtekintése",
    products_price_list_update: "Termékek - Árlista szerkesztése",
    products_price_list_menu: "Termékek - Árlista menü",

    products_setting_create: "Termékek - Beállítás létrehozása",
    products_setting_delete: "Termékek - Beállítás törlése",
    products_setting_view: "Termékek - Beállítás megtekintése",
    products_setting_update: "Termékek - Beállítás szerkesztése",
    products_setting_menu: "Termékek - Beállítások menü",

    webshop: "Webshop",
    webshop_coupon_create: "Webshop - Kupon létrehozása",
    webshop_coupon_delete: "Webshop - Kupon törlése",
    webshop_coupon_view: "Webshop - Kupon megtekintése",
    webshop_coupon_update: "Webshop - Kupon szerkesztése",
    webshop_coupon_menu: "Webshop - Kupon menü",

    webshop_order_create: "Webshop - Rendelés létrehozása",
    webshop_order_delete: "Webshop - Rendelés törlése",
    webshop_order_view: "Webshop - Rendelés megtekintése",
    webshop_order_update: "Webshop - Rendelés szerkesztése",
    webshop_order_menu: "Webshop - Rendelés menü",

    webshop_setting_create: "Webshop - Beállítás létrehozása",
    webshop_setting_delete: "Webshop - Beállítás törlése",
    webshop_setting_view: "Webshop - Beállítás megtekintése",
    webshop_setting_update: "Webshop - Beállítás szerkesztése",
    webshop_setting_menu: "Webshop - Beállítások menü",

    humanResources_employee_menu: "HR - Munkatársak menu",
    humanResources_employee_create: "HR - Munkatárs létrehozása",
    humanResources_employee_delete: "HR - Munkatárs törlése",
    humanResources_employee_update: "HR - Munkatárs szerkesztése",
    humanResources_employee_view: "HR - Munkatárs megtekintése",

    filings_filing_create: "Iktató - Iktató létrehozása",
    filings_filing_delete: "Iktató - Iktató törlése",
    filings_filing_view: "Iktató - Iktató megtekintése",
    filings_filing_update: "Iktató - Iktató szerkesztése",
    filings_filing_menu: "Iktató - Iktató menü",

    filings_filingBook_create: "Iktató - Iktatókönyv létrehozása",
    filings_filingBook_delete: "Iktató - Iktatókönyv törlése",
    filings_filingBook_view: "Iktató - Iktatókönyv megtekintése",
    filings_filingBook_update: "Iktató - Iktatókönyv szerkesztése",
    filings_filingBook_menu: "Iktató - Iktatókönyv menü",

    addresses_address_create: "Helyszínek - Helyszín létrehozása",
    addresses_address_delete: "Helyszínek - Helyszín törlése",
    addresses_address_view: "Helyszínek - Helyszín megtekintése",
    addresses_address_update: "Helyszínek - Helyszín szerkesztése",
    addresses_address_menu: "Helyszínek - Helyszínek menü",

    humanResources_year_create: "HR - Munkaév létrehozása",
    humanResources_year_delete: "HR - Munkaév törlése",
    humanResources_year_view: "HR - Munkaév megtekintése",
    humanResources_year_update: "HR - Munkaév szerkesztése",
    humanResources_year_menu: "HR - Nevezetes napok menü",

    humanResources_holiday_viewAny: "HR - Szabadságok megtekintése (mindenki)",
    humanResources_holiday_update: "HR - Szabadságok szerkesztése",
    humanResources_holiday_menu: "HR - Szabadságok menü",

    humanResources_holidayRequest_create: "HR - Szabadság igény létrehozása",
    humanResources_holidayRequest_view: "HR - Szabadság igény megtekintése",
    humanResources_holidayRequest_update: "HR - Szabadság igény szerkesztése",
    humanResources_holidayRequest_delete: "HR - Szabadság igény törlése",
    humanResources_holidayRequest_approval: "HR - Szabadság engedélyezése",
    humanResources_holidayRequest_menu: "HR - Szabadság igények menü",

    cafeteria_cafeteriaStatementData_create:
      "Cafeteria nyilatkozat létrehozása",
    cafeteria_cafeteriaStatementData_delete: "Cafeteria nyilatkozat törlése",
    cafeteria_cafeteriaStatementData_view: "Cafeteria nyilatkozat megtekintése",
    cafeteria_cafeteriaStatementData_menu: "Cafeteria nyilatkozatok menü",

    cafeteria_cafeteriaStatement_menu: "Cafeteria sablon menü",
    cafeteria_cafeteriaStatement_view: "Cafeteria sablon megtekintése",
    module_cafeteria_menu: "Cafateria modul",

    partner_connections_partner_connection_upload:
      "Partnerkapcsolatok feltöltése",
    partner_connections_partner_connection_view:
      "Partnerkapcsolatok megtekintése",
    partner_connections_partner_connection_menu: "Partnerkapcsolatok menü",

    partner_connections_setting_create:
      "Parnerkapcsolatok - Partnerkapcsolat beállítások létrehozása",
    partner_connections_setting_delete:
      "Parnerkapcsolatok - Partnerkapcsolat beállítások törlése",
    partner_connections_setting_view:
      "Parnerkapcsolatok - Partnerkapcsolat beállítások megtekintése",
    partner_connections_setting_update:
      "Parnerkapcsolatok - Partnerkapcsolat beállítások szerkesztése",
    partner_connections_setting_menu:
      "Parnerkapcsolatok - Partnerkapcsolat beállítások menü",

    institutes_institute_create: "Intézmények - Intézmény létrehozása",
    institutes_institute_delete: "Intézmények - Intézmény törlése",
    institutes_institute_view: "Intézmények - Intézmény megtekintése",
    institutes_institute_update: "Intézmények - Intézmény szerkesztése",
    institutes_institute_menu: "Intézmények - Intézmények menü",

    institutes_setting_create: "Intézmények - Intézmény beállítás létrehozása",
    institutes_setting_delete: "Intézmények - Intézmény beállítás törlése",
    institutes_setting_view: "Intézmények - Intézmény beállítás megtekintése",
    institutes_setting_update: "Intézmények - Intézmény beállítás szerkesztése",
    institutes_setting_menu: "Intézmények - Intézmény beállítások menü",

    system: "Rendszer beállítások",
    companySettings: "Vállalati beállítások",
    module: "Modulok",
    pages: "Oldalak",
    contactForms: "Űrlapok",
    quotations: "Ajánlások",
    blog: "Blog",
    news: "Hírek",
    events: "Események",
    logos: "Logók",
    popups: "Popup",
    colleagues: "Oktatók",
    gdpr: "Beállítások",
    external_references: "Külső hivatkozások",
    galleries: "Galériák",
    publication: "Díjeső",
    humanResources: "HR",
    filings: "Iktató",
    addresses: "Helyszínek",
    cafeteria: "Cafeteria ",
    partner_connections: "Parnerkapcsolatok ",
  },

  FORMS: {
    new: "Új",
    news: "Hírek",
    events: "Események",
    menu: "Menü",
    edit_menu: "Menük kezelése",
    menus: "Menük kezelése",
    cancel: "Mégse",
    save: "Mentés",
    download: "Letöltés",
    saved: "Mentve",
    status: "Státusz",
    name: "Név",
    label: "Cím",
    parent: "Szülő esemény",
    icon: "Ikon",
    target: "Cél",
    slug: "Slug",
    active: "Aktív",
    inactive: "Inaktív",
    are_you: "Valóban törölni szeretnéd a kiválasztott elemet",
    edit: "Szerkesztés - ",
    editPage: "Oldal szerkesztése",
    pageName: "Oldal neve",
    new_role: "Új szerepkör",
    excel_export: "Export xls",
    excel_exporting: "Exportálás excel fájlba",
    description: "Leírás",
    short_description: "Rövid leírás",
    shared_files: "Gyökér könyvtár",
    countries: "Országok",
    upload: "Feltölt",
    highlighted_text: "Kiemelt szöveg",
    requirements: "Elvárások",

    search: "Keresés",
    user: "Felhasználó",
    users: "Felhasználók",
    userGroup: "Felhasználói csoport",
    userGroups: "Felhasználói csoportok",
    roles: "Jogosultságok",
    sites: "Weboldalak",
    choose_site: "Válasszon weboldalt",

    firstName: "Vezetéknév",
    middleName: "Középső név",
    lastName: "Keresztnév",
    language: "Nyelv",
    password: "Jelszó",
    synergy_password: "Synergy Fox jelszó",
    userGroupName: "Felhasználói csoport neve",
    select: "Kiválaszt",
    contact_form: "Űrlapok",
    close: "Bezár",
    text: "Szöveg",
    intro: "Bevezető szöveg",
    position: "Pozíció",
    email: "E-mail",
    phone: "Telefonszám",
    seo_title: "SEO cím",
    seo_description: "SEO leírás",
    companyName: "Cég neve",
    select_users: "Válassz felhasználót",
    newsletter: "Hírlevél",
    country: "Ország",
    id: "Azonosító",
    currency: "Pénznem",
    sign: "Pénzjel",
    exchange_rate: "Árfolyam",
    format: "Formátum",
    permissions: "Jogosultságok",
    active_countries: "Aktivált országok",
    login: "Belépés",
    fields: "Mezők",
    system_settings: "Rendszer beállítások",
    new_currency: "Új pénznem",
    currencies: "Pénznemek",
    actions: "Műveletek",
    select_site_language_order: "Az oldal nyelvei",
    content_language: "Tartalom nyelve",
    system_language: "A rendszer nyelve",
    date_of_created_at: "Létrehozás dátuma",
    time_of_created_at: "Létrehozás ideje",
    add: "Hozzáad",
    block1: "Blokk 1",
    block2: "Blokk 2",
    block3: "Blokk 3",
    block4: "Blokk 4",
    block5: "Blokk 5",
    block6: "Blokk 6",
    quotation_block: "Rólunk mondták blokk",
    usp: "USP",
    modules: "Modulok",
    contact_block: "Kapcsolat blokk",
    company_settings: "Cég beállítások",
    title: "Cím",
    hero_title: "Hero cím",
    hero_text: "Hero szöveg",
    hero_button_text: "Hero gomb szövege",
    hero_button_url: "Hero gomb URL-je",
    solutions_title: "Megoldások cím",
    solution1_title: "Megoldás 1 cím",
    solution1_text: "Megoldás 1 szöveg",
    solution1_url: "Megoldás 1 url",
    solution2_title: "Megoldás 2 cím",
    solution2_text: "Megoldás 2 szöveg",
    solution2_url: "Megoldás 2 url",
    solution3_title: "Megoldás 3 cím",
    solution3_text: "Megoldás 3 szöveg",
    solution3_url: "Megoldás 3 url",
    solution4_title: "Megoldás 4 cím",
    solution4_text: "Megoldás 4 szöveg",
    solution4_url: "Megoldás 4 url",
    quotes_title: "Ajánlások cím",
    quotes_text: "Ajánlások szöveg",
    tryout_text: "Próbálja ki szöveg",
    tryout_button_text: "Kipróbálás gomb szövege",
    tryout_button_url: "Kipróbálás gomb url",
    timeLine: "Idővonal",
    year: "Évszám",
    colleagues: "Kollégáink",
    surname: "Vezetéknév",
    block_partners: "Partnereink blokk",
    not_found: "Nincs találat!",
    preview: "Megtekintés",
    code: "Kód",
    date_from: "Kezdete",
    date_to: "Vége",
    visible_from: "Látható ekkortól",
    visible_to: "Látható eddig",
    latitude: "Földrajzi szélesség",
    longitude: "Földrajzi hosszúság",
    canonical: "Canonical URL",
  },

  SALESORDERSSTATUSES: {
    new: "New",
    waiting_for_offer: "Waiting for offer",
    offer_approval: "Offer approval",
    offer_done: "Offer done",
    order_approval: "Order approval",
    ordered: "Ordered",
    contracted: "Contracted",
    to_be_produced: "To be produced",
    in_production: "In production",
    to_be_delivered: "To be delivered",
    delivered: "Delivered",
    closed: "Closed",
    revoked: "Revoked",
    webshop: "Webshop",
    blog: "Blog",
  },

  ORDER_STATUSES: {
    0: "Félbehagyott rendelés",
    1: "Törölt rendelés",
    2: "Kész (teljesített)",
    3: "Új rendelés",
  },

  FORM_INPUT_NAMES: {
    parent_category: "Szülő kategória",
    total_net: "Nettó ár",
    total_gross: "Bruttó ár",
    total_net_special: "Akciós nettó ár",
    total_gross_special: "Akciós bruttó ár",
    owner_id: "Owner",
    paid: "Paid",
    lost: "Lost",
    source_type_id: "Forrás típusa",
    locale: "Locale",
    customer: "Customer",
    billingAddress: "Billing address",
    shippingAddress: "Shipping address",
    expiration_date: "Expiration date",
    delivery_date: "Delivery date",
    delivery_days: "Delivery days",
    price_list_id: "Price list",
    discount_value: "Discount value",
    discountNetValue: "Discount net value",
    notes: "Megjegyzés",
    status: "Státusz",
    id: "Id",
    sent: "Sent",
    zero_tax: "Zero tax",
    addresses: "Adresses",
    address: "Cím",
    city: "Város",
    zip: "Irányítószám",
    price_net: "Price net",
    so_number: "SO number",
    approved: "Approved",
    company_id: "Company Id",
    contact_id: "Contact Id",
    attachments: "Attachments",
    old_version: "Old version",
    price_gross: "Price gross",
    discount_type: "Discount type",
    sender_name: "Feladó neve",
    sender_email: "Feladó email címe",
    languages: "Nyelvek",
    select_logo: "Válassz logót",
    select_header_logo: "Válassz fejléc logót",
    select_footer_logo: "Válassz lábléc logót",
    select_popup: "Válassz bejegyzést",
    select_icon: "Válassz ikont",
    select_image: "Válassz képet",
    select_favicon: "Válassz favicon-t",
    select_languages: "Válassz nyelveket",
    select_public_languages: "Válassz publikus nyelveket",
    alt_text: "Helyettesítő szöveg",
    title: "Cím",
    title2: "Cím 2. sor",
    description: "Leírás",
    url: "URL",
    youtube_url: "YouTube URL",
    gtm: "GTM",
    recaptcha_site_key: "reCAPTCHA site key",
    recaptcha_site_secret: "reCAPTCHA site secret",
    button_text: "Gomb szövege",
    text1: "Cím",
    text2: "Alcím",
    name: "Név",
    surname: "Vezetéknév",
    message: "Üzenet",
    resellerLicence: "Működési engedély száma",
    type: "Típus",
    desktop_image: "Asztali kép",
    mobile_image: "Mobil kép",
    galery: "Galéria",
    tags: "Címkék",
    featured_image: "Kiemelt kép",
    card_image: "Kártyakép",
    placement: "Elhelyezés",
    category: "Kategória",
    select_range: "Válassz intervallumot",
    og_site_name: "OG oldal neve",
    og_title: "OG cím",
    og_type: "OG típusa",
    og_url: "OG URL",
    og_description: "OG leírása",
    og_image: "OG kép feltöltése",
    related_posts: "Rögzített bejegyzés ajánló",
    related_news: "Rögzített hír ajánló",
    select_ExternalReference: "Külső hivatkozás",
    url_prefix: "URL előtag",
    icon: "Ikon",
    gallery: "Galéria",
    site_title: "Az intézmény neve",
    select_publication_item: "Válassz elemet",
    year: "Év",
    gMapURL: "Google térkép URL",
    question: "Kérdés",
    answer: "Válasz",
    select_background_image: "Válassz háttérképet",
    select_service_image: "Válassz szolgáltatás képet",
    select_usp_image: "Válassz USP képet(svg)",
    hero_button_text: "Hero gomb szöveg",
    hero_button_url: "Hero gomb URL",
    text: "Szöveg",
    select_module_image: "Válassz modul képet",
    main_page: "Főoldalon",
    company_name: " Neve",
    button_url: "Gomb URL",
    company_tax_number: "Adószám",
    company_registration_number: "Cégjegyzékszám",
    company_bank_account_number: "Bankszámlaszám",
    company_address: "Cím",
    company_phone: "Telefon",
    company_fax: "Fax",
    company_email: "Email",
    company_gmap: "Google térkép URL",
    company_slogen: "Történet",
    sub_title: "Alcím",
    select_numbers: "Válassz számokat",
    select_hero_image: "Válassz hero háttérképet",
    select_solutions_image: "Válassz megoldás képet",
    new_block: "Új blokk",
    block: "Blokk",
    block1: "1. blokk",
    block2: "2. blokk",
    block3: "3. blokk",
    block4: "4. blokk",
    block5: "5. blokk",
    block6: "6. blokk",
    block7: "7. blokk",
    block8: "8. blokk",
    block9: "9. blokk",
    block10: "10. blokk",
    iframe: "IFRAME",
    select_colleagues: "Válassz kollégát",
    position: "Pozíció",
    date: "Dátum",
    special_offer: "Kiemelt ajánlatok",
    offer: "Ajánló",
    recommend: "Ajánljuk",
    shipping_quantity: "Kiszálítási egység",
    quantity_unit: "Mértékegység",
    product_offer: "Kiemelt termék",
    product_name: "Termék neve",
    select_slogan_image: "Válassz slogen képet",
    footer_offer: "Alsó ajánló",
    select_youtube_image: "Válassz youtube képet",
    taste_map: "Íztérkép",
    dry: "Száraz",
    sweet: "Édes",
    full_bodied: "Testes",
    light: "Könnyű",
    shipping_price: "Szállítási költség",
    free_shipping_from_price: "Ingyenes szállítás a megadott kosárérték felett",
    ordered_at: "Megrendelve",
    isPackage: "Termék típusa",
    sub_text1: "Alsó szöveg1",
    sub_text2: "Alsó szöveg2",
    webshop: "Webshop",
    blog: "Blog",
    banner: "Banner",
    select_banner: "Válassz bannert",
    events: "Esemémyek",
    company_bank_iban: "IBAN",
    company_bank_swift: "SWIFT",
    image_resolution: "Ajánlott felbontás",
    select_all: "Összes kijelölése",
    delete_selected: "A kijelöltek törlése?",
    selectCountry: "Válassz országot",
    contact_form_types: "Űrlap típusok",
    contact_form_modal: "Űrlap típusok szerkesztése",
    select_slider: "Válassz lapozót",
    select_gallery: "Válassz galériát",
    editing_custom_field: "CF szerkesztő",
    editing_accordion: "Szerkesztő",
    save_template: "Template mentése",
    date_time: "Dátum, idő",
    dateRange: "Dátum, -tól -ig",
    import_images: "Képek másolása",
    exit_popup: "Exit popup",
    dashboard: "Dashboard iframe",
    intro: "Bevezető szöveg",
    enable_gmc: "Google Merchant Center",
    gmc_category_id: "Google Merchant Center - kategória azonosító",
    foreignFallbackLocale: "Visszatérés nyelve",
    selectForeignFallbackLocale: "Válassz alapértelmezett nyelvet",
    warning_text: "Figyelmeztető szöveg",
    show_warning_text: "Figyelmeztető szöveg megjelenitése",
    default_settings: "Általános beállítások",
    company_data: "Cég adatok",
    subject: "Tárgy",
    keywords: "Kulcsszavak",
    source_text: "Forrás szöveg",
    select_type: "Válassz típust",
    social_links: "Social linkek",
    response_text: "Generált tartalom",
    generate_response: "Küldés",
    tokenizer: "Becslés",
    temperature: "Kreativitás",
    char_length: "Karakterek száma",
    number: "Verzók száma",
    SF_asistant: "Fox AI",
    subject_label: "Üzenet",

    date_from: "Dátum, -tól",
    date_to: "Dátum, -ig",
    contact_form_name: "Űrlap neve",
    contact_form: "Űrlap",
    required: "Kötelező mező",
    input_fields: "Bemeneti mezők",
    selector_options: "Választási lehetőségek",
    notification_emails: "Értesítési e-mail címek",

    templateType_text: "Szöveg",
    templateType_textarea: "Szöveg doboz",
    templateType_email: "E-mail",
    templateType_checkbox: "Jelölőnégyzet",
    templateType_select: "Legördülő lista",
    templateType_multiselect: "Több választós lista",
    placeholder: "Segítő szöveg",
    label: "Kiírt mezőnév",
    variable_name: "Változónév",
    not_working_day: "Szabadnap",
    lot_number: "Helyrajzi szám",
    on_main_page: "Főoldalon szerepel",
    visible_in_list: "Listanézetben látható",
    visible_in_calendar: "Naptárnézetben látható",
    accentuated: "Kiemelt",
    subpages: "Aloldalak",
    tag_title: "Tagozat név",
    deleted_field: "Törölt mező",
    monogram: "Rövidítés",
  },

  ACTIVITY: {
    not_logged_id: "Customer",
  },

  ADJUSTMENT: {
    status_null: "",
    status_undefined: "",
    status_0: "New",
    status_1: "Under production",
    status_2: "Under procurement",
    status_3: "Remainder",
    status_4: "On hand",
    status_5: "Build in",
    status_6: "Released",
    status_7: "Shipping",
    status_8: "Delivered",
    status_9: "Damaged",
    status_10: "Lost",

    typeOf_1: "Add",
    typeOf_2: "Remove",
    typeOf_3: "Edit",
  },

  ERRORS: {
    file_already_exists: "A fájl már létezik",
    field_is_required: "Kötelező mező",
    must_be_max_characters: "A mező hossz max 250 karakter lehet",
    language_is_required: "A nyelv megadása kötelező",
    currency_is_required: "A pénznem megadása kötelező",
    email_is_required: "Az E-mail cím megadása kötelező",
    email_is_valid: "Az E-mail cím formátuma nem megfelelő",
    site_is_required: "A weboldal megadása kötelező",
    user_group_is_required: "A felhasználó csoport megadása kötelező",
    role_is_required: "A szerepkör megadása kötelező",
    password_is_required: "A jelszó megadása kötelező",
    minimum_value: "Minimum érték {minNum}",
    maximum_length: "Maximum hossz {maxLen}",
    one_time_password_is_valid: "Érvénytelen kód",
  },

  TABLE: {
    created_at: "Létrehozva",
    name: "Név",
    status: "Státusz",
    actions: "Műveletek",
    type: "Típus",
    page: "Oldal",
    slug: "Slug",
    post: "Bejegyzés",
    blog_category: "Blog kategória",
    news_category: "Hír kategória",
    event_category: "Esemény kategória",
    publication_category: "Kategória",
    created: "Létrehozva",
    phone: "Telefon",
    company: "Vállalat",
    quotationCategory: "Ajánlás kategória",
    logo: "Logó",
    colleagueCategory: "Oktató kategória",
    id: "Azonosító",
    country: "Ország",
    language: "Nyelv",
    currency: "Pénznem",
    eu: "EU",
    are_you_sure_delete: "Valóban törölni szeretnéd a kiválasztott elemet?",
    sign: "Pénzjel",
    exchange_rate: "Árfolyam",
    cannot_delete: "Ez az elem nem törölhető",
    translated: "Fordítás",
    first_name: "Vezetéknév",
    christian_name2: "Középső név",
    last_name: "Keresztnév",
    ok: "Ok",
    cancel: "Mégse",
    yes: "Igen",
    no: "Nem",
    categories: "Kategóriák",
    amount: "Összesen",
    value: "Érték",
    code: "Kód",
    size: "Méret",
    year: "Év",
    filingBooks: "Iktatókönyv",
    zip: "Irányítószám",
    city: "Város",
    address: "Cím",
    from: "Esemény kezdete",
    to: "Esemény vége",
    parent: "Szülő oldal",
    updated_at: "Módosítva",
  },

  MEDIA_LIBRARY: {
    create_new_directory: "Új könyvtár",
    rename_directory: "Könyvtár átnevezése",
    rename_file: "Fájl átnevezése",
    file_upload: "Fájlok feltöltése",
    please_select: "Kérem válasszon",
    uploading: "Feltöltés",
    edit_image: "Kép szerkesztése",
    crop_and_save: "Vágás és mentés",
    crop_and_select: "Vágás és kiválasztás",
    file_upload_max_size: "Feltölthető max. fájlméret",
    file_upload_max_count: "Maximálisan feltölthető fájlok darabszáma",

    select_media: "Válasszon tartalmat",
  },

  EXCELL: {
    created_at: "Létrehozva",
    first_name: "Vezetéknév",
    last_name: "Keresztnév",
    email: "Email",
    phone: "Telefonszám",
    company_name: "Vállalat",
    message: "Üzenet",
    locale: "Nyelv",
    remote_ip: "Távoli IP cím",
    user_agent: "User agent",
    name: "Név",
    contact_form_id: "Űrlap ID",
  },

  SELECTS: {
    1: "Általános",
    2: "URL",
    3: "Szöveg",
    4: "PDF",
  },

  BANNERS: {
    BG_OF_BANNER_IS_IMAGE: "Banner kép",
    BG_OF_BANNER_IS_EMBEDDED_VIDEO: "Banner beágyazott videó",
  },

  PRICE_LIST: {
    currency: "Pénznem",
    productName: "Termék neve",
    searchProduct: "Válassz terméket",
    priceListName: "Árlista neve",
    defaultTaxName: "Adó neve", // Tax name
    defaultTaxRate: "Adó értéke",
    productsOnPriceList: "Termékek az árlistán", // List of items on this price list
    productPriceNet: "Termék nettó ára", // Product price net
    productPriceGross: "Termék bruttó ára", // Product price gross
  },

  COUPONS: {
    coupons: "Kuponok",
    coupon: "Kupon",
    max_use_num: "Maximum felhasználható",
    max_use_num_per_user: "Maximum felhasználható felhasználóként",
    value: "Érték",
  },
  WEBSHOP: {
    customers: "Felhasználók",
    customer: "Felhasználó",
    orders: "Rendelések",
    order: "Rendelés",
    shipping_address: "Szállítási cím",
    billing_address: "Számlázási cím",
  },
  STATUSES: {
    inactive: "Inaktív",
    active: "Aktív",
    expired: "Lejárt",
    revoked: "Visszavont",
    closed: "Lezárt",
    submitted: "Beküldött",
    approved: "Elfogadott",
    rejected: "Elutasított",
  },

  SNACK_BAR: {
    saved: "Sikeresen elmentve",
  },
};
